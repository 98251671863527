/* form group animation */
.form-group.ng-enter,
.form-group.ng-leave {
    transition: all linear 0.5s;
}
   
.form-group.ng-enter,
.form-group.ng-leave.ng-leave-active {
    opacity: 0;
}

.form-group.ng-leave,
.form-group.ng-enter.ng-enter-active {
  opacity:1;
}

.modalWindowClass {
    z-index: 99000 !important;
}

.zindex2 {
    z-index: 98000 !important;
}

.fade.in {
    opacity: 1 !important;
}

.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5 !important;
}

.modal {
    text-align: center;
}


.modal-dialog {
    text-align: left;
    margin-top: 170px !important;
}

.logo_bnmx {
    width: 30%;
    padding-bottom: 1%;
}
@media (max-width: 450px) {
    .logo_bnmx {
        width: 45%;
        padding-bottom: 1%;
    }
}

.Title {
    width: 30%;
    color: #000000;
    text-align: center;
    font-size: 45px;
  }

  .top {
    color: #f60f0c;
  }

  .tarjetas {
    color: #26adcd;
  }


  .amex-section {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    vertical-align: top;
  }

  .gold {
    font-size: 20px;
  }

  .dosmil {
    font-size: 40px;
  }

  .SubTitle {
    width: 100%;
    color: #2E77BB;
    text-align: left;
    font-size: 30px;
  }

  .Terminos {
    width: 100%;
    color: #999999;
    text-align: left;
    font-size: 15px;
  }

  .listo {   
    padding: 50px;
  }

  .beneficiot {
    color: #2e77bb;
    font-size: 25px;
    text-align: center;
    margin-top: 5%;
  }

  .beneficio {
    color: #2e77bb;
    font-size: 15px;
    text-align: center;
    padding: 0% 5% 0% 5%;
  }


  .flechaImg {
      width: 40%;
  }

  .button_amex {
      width: 40%;
      padding: 2%;
      font-size: 180%;
  }

  @media (max-width: 650px) {
    .flechaImg {
        display: none;
    }

    .button_amex {
      width: 40%;
      padding: 5%;
      font-size: 150%;
    }
}


@import url('https://fonts.googleapis.com/css?family=Montserrat:300,500|Poppins:600&display=swap');

body{
    margin: none !important;
	font-family: 'Montserrat', sans-serif;
	font-weight: 300;
}
.img{
	max-width: 100%;
}
.font-weight-bold{
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
}

.relative{
	position: relative;
}

.overlay-dark, .overlay-dark40, .overlay-light, .overlay-light80{
    position: relative;
    z-index: 1;
}

.overlay-dark:before,  .overlay-dark40:before, .overlay-light:before, .overlay-light80:before {
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    z-index: -1;
}

.overlay-dark:before {
    background-color: rgba(34,34,34,0.1);
}

.overlay-dark40:before {
    background-color: rgba(34,34,34,0.6);
}

.overlay-light:before {
    background-color: rgba(255,255,255,0.4);
}

.overlay-light80:before {
    background-color: rgba(255,255,255,0.2);
}

/*  B A C K G R O U N D S  */

.bg-gray{
	background: #f9f9f9;
}
.bg-black{
	background: #000;
}
.bg-body{
	background: #131313
}
.bg-white-transparent{
    background: rgba(255, 255, 255, .8);
    border-radius: 10px;
    width: 90%;
    margin: auto;
    padding-top: 20px;
    padding-bottom: 20px;
}

/*  S P A C I N G    A N D    H E I G H T  */

.h100{
	min-height: 100vh;
}
.h85{
    min-height: 85vh;
}

.w100{
	width: 100%;
}


.section-padding{
	margin: auto;
}

@media (min-width: 768px){
	.section-padding{
		padding-top: 130px;
		padding-bottom: 130px;
	}
}

.text-blue{
    color: #253fe8;
}

.imgModal{
    width: 100% !important;
}

.btn-big {
    width: 68%;
    height: 50px;
    line-height: 2.2;
}

/*  S T Y L E S      B U T T O N S  */

.btn-default{
	font-weight: 300;
}
.btn-gradient4{
	color: #fff;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2abcd6+0,253fe8+100,7db9e8+100,207cca+101 */
background: #2abcd6; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to right, #2abcd6 0%,#253fe8 100%,#7db9e8 100%,#207cca 101%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2abcd6', endColorstr='#207cca',GradientType=1 ); /* IE6-9 */
}

.btn-gradient5{
    color: #fff;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2abcd6+0,253fe8+100,7db9e8+100,207cca+101 */
    background: orange; /* Old browsers */
}

.btn-gradient6{
    color: #fff;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2abcd6+0,253fe8+100,7db9e8+100,207cca+101 */
    background: red; /* Old browsers */
}

@media (max-width: 320px) {
    .btn-gradient4{
        font-size: 12px;
    }

    .btn-gradient5{
        font-size: 12px;
    }
}


.btn-arrow span {
    display: inline-block;
    position: relative;
    transition: all 300ms ease-out;
    will-change: transform;
}

.btn-arrow:hover span, .btn-arrow:focus span {
    transform: translate3d(-1rem, 0, 0);
    color:#fff;
}
.btn-arrow i {
    position: absolute;
    width: 1.1em;
    right: 0px;
    right: 0rem;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: all 300ms ease-out;
    will-change: right, opacity;
}

.btn-arrow:hover i, .btn-arrow:focus i {
    opacity: 1;
    right: -2rem;
}

/*  S T Y L E S         N A V B A R */

.navbar{
	background-color: transparent;
	transition: all ease 0.5s; 
    box-shadow: -1px 5px 15px -6px #6C6C6C;
}

.navbar.scrolled{
	/*background-color: #fff;*/
}
.navbar-nav .nav-link {
    color:#F0C501;
    /*text-shadow: 1px 1px 1px #000;*/
}
.navbar-nav .nav-link.active {
    color:#EE8419 !important;
}
.navbar-brand img{
    max-width: 100%;
}
@media (max-width: 320px) {
    .navbar-brand{
        margin-left: 0 !important;
        width: 220px;
    }   
}

@media (max-width: 900px ){
    .navbar-brand{
        width: 76%;
    }
}
@media (min-width: 768px) {
    .navbar-brand img{
        max-width: 65%;
    }
}
@media (min-width: 1200px) {
    .navbar-brand img{
        max-width: 100%;
    }
}



/*  I C O N S     S I Z E  */

.icon-5x{
	font-size: 5rem;
}
.text-transparent{
	color: transparent;
}
.text-gradient{
	background: -webkit-linear-gradient(#e84a2e, #b71d86);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient2{
    background: -webkit-linear-gradient(#2abcd6, #253fe8);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.text-gradient3{
    background: -webkit-linear-gradient(#2abcd6, green);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-gradient.active{
	background: -webkit-linear-gradient(#fff, #fff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
/*   S T Y L E S        I N T R O   */

.bg-intro2{
	background: url(../images/bg-intro2.jpg) center top no-repeat;
	background-size: cover; 
}
.bg-faq{
    background-image: url(../images/bg-faq.jpg);
    background-size: cover; 
}
.bg-afiliado{
    background-image: url(../images/afiliados.jpg);
    background-size: cover; 
}
.bg-terms{
    background-image: url(../images/bg-terms.jpg);
    background-size: cover; 
}
.bg-privacy{
    background-image: url(../images/bg-privacy.jpg);
    background-size: cover; 
}
.bg-contact{
    background-image: url(../images/bg-contact.jpg);
    background-size: cover; 
}


.title{
	font-size: 2.8rem;
    letter-spacing: -2px;
}
.subtitle{
	font-size: 1.5rem;
}
@media (max-width: 320px) {
    .title{
        font-size: 2rem;
    }
    .subtitle{
        font-size: 1.1rem;
    }
}
@media (max-width: 768px) {
    .title{
        font-size: 2rem;
    }
    .subtitle{
        font-size: 15px;
    }
    .btn-big {
        height: 40px;
    }

    .bg-intro2{
        background: url(../images/bg-intro2cc.jpg) center top no-repeat;
        background-size: cover; 
    }

    .container-amex{
        margin-top: 75px;
    }
}

@media (min-width: 768px) {
	.title{
		font-size: 3.2rem;
        letter-spacing: -1px;
	}
	.subtitle{
		font-size: 1.8rem;
	}
}

/*   S T Y L E S        B E N E F I T S   */
.big {
    font-size: 2.8rem;
    color: #ccc;
    position: absolute;
    top: 20px;
    left: 0;
    opacity: 0.6;
    letter-spacing: -2px;
}



@media (max-width: 768px) {
	.big{
        font-size: 2rem;
        position: relative;
    }
    .centerText{
        text-align: center;
    }
    .point{
        -ms-flex-direction: column;
            flex-direction: column;
    }
    .icon-5x{
        font-size: 3rem;
    }
}

.number-top{
	position: absolute;
	top: 5px;
	left: 5px;
	color: #fff;
	padding: 5px 15px;
	border-radius: 50%;
}

#azteca1{
    width: 100%;
}
#azteca2{
    width: 100%;
}
#azteca3{
    width: 100%;
}

.button_azteca {
    width: 60%;
    font-size: 180%;
    height: 60px;
    background: #17a54d !important;
}

.spinner{
    width: 5%;
    margin: 20px;
}

@media(max-width: 700px){
    .button_azteca{
        font-size: 15px;
        background-color: #17a54d;

    }
    
    .spinner{
        width: 10px;
    }

    .text-footer-azteca{
        font-size: 20px;
    }

    #azteca1{
        display: none;
    }
    #azteca2{
        width: 50%;
    }
    #azteca3{
        width: 50%;
    }
}

@media(min-width: 768px) {
	.big{
		font-size: 5.6rem;
		top: -25px;
		letter-spacing: -4px;
    }
}

@media(min-width: 992px) {
	.big{
		font-size: 6rem;
		top: -25px;
	}
}

/*  F O O T E R  */
.logo-footer img{
    max-width: 40%;
}

.btn-facebook{
	background: #3b5998;
	color: #fff;
}
.btn-facebook:hover, .btn-facebook:focus{
	background: #344F87;
	color: #fff;
}
.list-footer{
	list-style: none;
	color: #fff;
	font-size: 0.85rem;
	margin-bottom: 2rem;
}
.list-footer li{
	width: 100%;
}




/*  F O R M  */
.raised {
    -mox-box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5) , 0 1px 4px rgba(0, 0, 0, 0.3) , 0 0 40px rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0 15px 10px -10px rgba(0, 0, 0, 0.5), 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}

.card-form{
	background-color: transparent;
	width: 100%;
}
.card-header-form{
	color: #fff;
	font-size: 1.2rem;
	padding: 15px 30px;
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	font-family: 'Poppins', sans-serif;
	font-weight: 600;
}

.card-body-form{
	background: #fff;
	padding: 40px 28px 15px;
}
.card-body-form p{
	font-size: 0.9rem;
}
.card-footer-form{
	background-color: #f3f3f3;
	padding: 20px;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

@media (min-width: 768px){
	.card-body-form{
		background: #fff;
		padding: 40px 40px 15px;
	}
}

input.form-top, textarea.form-top{
	border: 2px solid #253fe8;
}


.middle {
    width: 100%;
    text-align: center;
}

.middle input[type="radio"] {
    display: none;
}

.middle input[type="radio"]:checked + .box {
    background-color: #0D6AB8;
}
.middle input[type="radio"]:checked + .box span {
    color: white;
    transform: translateY(40px);
}
.middle input[type="radio"]:checked + .box span:before {
    transform: translateY(-30px);
    opacity: 1;
}

.middle .box {
    width: 120px;
    height: 60px;
    border:2px solid #0D6AB8;
    border-radius: 5px;
    background-color: #fff;
    transition: all 250ms ease;
    will-change: transition;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    position: relative;
    font-family: "Montserrat", sans-serif;
    overflow: hidden;
}
.middle .box.second{
	margin-left: 20px;
}

.middle .box span {
    position: absolute;
    transform: translate(0, -25px);
    left: 0;
    right: 0;
    transition: all 300ms ease;
    font-size: 1.1em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    color: #007e90;
}

.middle .box span:before {
    font-size: 1.6em;
    font-family: Pe-icon-7-stroke;
    display: block;
    transform: translateY(-60px);
    opacity: 0;
    transition: all 300ms ease-in-out;
    font-weight: normal;
    color: white;
}

.middle .front-end span:before {
    content: '\e66c';
}

.middle .back-end span:before {
    content: '\e681';
}
@media (max-width: 320px) {
    .middle .box{
        width: 80px;
    }
}


.radio-tile-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-pack: center;
        justify-content: center;
}

.radio-tile-group .input-container {
    position: relative;
    height: 5rem;
    width: 8rem;
    margin: 0.5rem;
}
.radio-tile-group .input-container.checkbox {
    position: relative;
    height: 3rem;
    width: 8rem;
    margin: 0.5rem;
}


.radio-tile-group .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
}

.radio-tile-group .input-container .radio-tile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    -ms-flex-align: center;
        align-items: center;
    -ms-flex-pack: center;
        justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #079ad9;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
}


.radio-tile-group.usd .input-container .radio-tile{
    padding: 0.5rem;
}

.radio-tile-group .input-container .radio-tile .icon img{
    max-width: 80%;
    margin-left: 10%;
}
.radio-tile-group .input-container .radio-button:checked + .radio-tile {
    background-color: #079ad9;
    border: 2px solid #079ad9;
    color: white;
    transform: scale(1.1, 1.1);
}
.radio-tile-group .input-container .radio-button:checked + .radio-tile.warning {
    background-color: #ffc107;
    border: 2px solid #ffc107;
    color: #000;
    transform: scale(1.1, 1.1);
}
.radio-tile-group .input-container .radio-button.one:checked + .radio-tile{
    background-color: #18b5fd;
    border: 2px solid #18b5fd;
}
.radio-tile-group .input-container .radio-button.two:checked + .radio-tile{
    background-color: #802ff1;
    border: 2px solid #802ff1;
}
.radio-tile-group .input-container .radio-button.three:checked + .radio-tile{
    background-color: #ff7900;
    border: 2px solid #ff7900;
}
.radio-tile-group .input-container .radio-button.four:checked + .radio-tile{
    background-color: #DF0F4E;
    border: 2px solid #DF0F4E;
}

.radio-tile-group .input-container .icon .fa {
    color: #000;
}
.radio-tile-group .input-container .radio-tile .icon i {
    color: #fff;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile .icon i {
    color: #fff;
}

.radio-tile-group .input-container .radio-tile-label {
    text-align: center;
    font-size: 0.65rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #000;
    font-weight: 500;
    margin-bottom: 0;
}
.radio-tile-group .input-container .radio-tile-label.bolean {
    font-size: 0.95rem;
}
.radio-tile-group .input-container.yellow .radio-tile-label {
    color: #000;
}

.radio-tile-group .input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: #fff;
    background-color: transparent;
}
.radio-tile-group .input-container .radio-button:checked + .radio-tile.warning .radio-tile-label {
    color: #000;
    background-color: transparent;
}

.checkbox {
  padding-left: 20px;
}

.checkbox label {
  display: inline-block;
  vertical-align: middle;
  position: relative;
  padding-left: 5px;
}

.checkbox label::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  left: 0;
  top: 5px;
  margin-left: -20px;
  border: 1px solid #cccccc;
  border-radius: 3px;
  background-color: #fff;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
  display: inline-block;
  position: absolute;
  width: 16px;
  height: 16px;
  left:-1px;
  top:2px;
  margin-left: -20px;
  padding-left: 3px;
  padding-top: 1px;
  font-size: 14px;
  color: #555555;
}

.checkbox input[type="checkbox"] {
  opacity: 0;
  z-index: 1;
}

.checkbox input[type="checkbox"]:focus + label::before {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

  
.checkbox input[type="checkbox"]:disabled + label {
  opacity: 0.2;
}

.checkbox input[type="checkbox"]:disabled + label::before {
  background-color: #eeeeee;
  cursor: not-allowed;
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
  background-color: #428bca;
  border-color: #428bca;
}

.checkbox-primary input[type="checkbox"]:checked + label::after {
  color: #fff;
}

@media (min-width: 768px) {
    .checkbox label::before {
        top: 2px;
    }
    .checkbox label::after{
        left:-1px;
        top:0px;
    }
}


/*  F A Q     S T Y L E S  */

.heading-title{
    min-height: 40vh;
}
.card-header{
    cursor: pointer;
    transition: all ease 0.5s;
}

.card-header.collapsed { 
    background-color: #F3F3F3 !important;
}
.card-header.collapsed:hover{
    background-color: #E2E2E2 !important;
}
.card-header.collapsed a{
    color: #000 !important;
}

.card-body{
    font-size: 85%;
    font-weight: 400;
}

@media (min-width: 992px) {
    .heading-title{
        min-height: 60vh;
    }  
}

